// Libraries
import {FormikValues} from 'formik';
import _ from 'lodash';

// Supermove
import {useMemo, useEffect} from '@supermove/hooks';
import {setFormServerErrors} from '@supermove/utils';

import {MutationResponse, Form} from './types';

type Args<FormValues extends FormikValues, Results extends Record<string, any>> = {
  form: Form<FormValues>;
  data: MutationResponse<Results>;
};

const useFormErrors = <
  FormValues extends FormikValues = Record<string, any>,
  Results extends Record<string, any> = Record<string, any>,
>({
  form: originalForm,
  data,
}: Args<FormValues, Results>): void => {
  // eslint-disable-next-line
  const form = useMemo(() => originalForm, [data]);

  useEffect(() => {
    const errors = _.get(data, 'response.errors');

    if (errors) {
      setFormServerErrors({form, errors});
    }
  }, [data, form]);
};

export default useFormErrors;
