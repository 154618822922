// Libraries
import React from 'react';

// Supermove
import {ViewStyleProp} from '@supermove/styles/types';

// Relative
import DragAndDropBuilder from './DragAndDropBuilder';

interface DragComponentProps {
  spaceBetweenItems?: number;
  isDisabled?: boolean;
  disabledIndexes?: number[];
  itemContainerStyle?: ViewStyleProp;
  isDraggingId?: string | number;
  isHorizontal?: boolean;
  childrenWithIds: React.ReactElement[];
}

const DragComponent = ({
  spaceBetweenItems = 0,
  isDisabled = false,
  disabledIndexes = [],
  itemContainerStyle,
  isDraggingId,
  isHorizontal,
  childrenWithIds,
}: DragComponentProps) => {
  if (!isDraggingId) return null;

  const activeChild = React.Children.toArray(childrenWithIds).find(
    (child): child is React.ReactElement =>
      React.isValidElement(child) && child.props.id === isDraggingId,
  );

  if (!activeChild) return null;

  const activeIndex = childrenWithIds.findIndex((child) => child.props.id === isDraggingId);

  return (
    <DragAndDropBuilder.ItemContainer
      spaceBetweenItems={spaceBetweenItems}
      isHorizontal={isHorizontal}
      style={itemContainerStyle}
    >
      {!isDisabled && !disabledIndexes.includes(activeIndex) && !isHorizontal && (
        <DragAndDropBuilder.DragIcon />
      )}
      {activeChild}
    </DragAndDropBuilder.ItemContainer>
  );
};

export default DragComponent;
