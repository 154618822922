// Libraries
import {KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {sortableKeyboardCoordinates} from '@dnd-kit/sortable';

const useDndKitSensors = () => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // 8px movement required before activation
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250, // Wait for 250ms before activation
        tolerance: 5, // Small tolerance for slight movement
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return sensors;
};

export default useDndKitSensors;
