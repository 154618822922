// Libraries
import {UniqueIdentifier} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React from 'react';

// Supermove
import {ViewStyleProp} from '@supermove/styles/types';

// Relative
import DragAndDropBuilder from './DragAndDropBuilder';

const DraggableWrapper = ({
  id,
  isGrabbing,
  isDragDisabled,
  isDraggable,
  style = {},
  children,
}: {
  id: UniqueIdentifier;
  isGrabbing: boolean;
  isDragDisabled: boolean;
  isDraggable?: boolean;
  style?: ViewStyleProp;
  children: React.ReactNode;
}) => {
  const {attributes, listeners} = useSortable({
    id,
    disabled: isDragDisabled,
  });

  if (isDraggable) {
    return (
      <div
        {...attributes}
        {...listeners}
        style={{
          cursor: isDragDisabled ? 'auto' : isGrabbing ? 'grabbing' : 'grab',
          touchAction: 'none',
          userSelect: 'none',
          WebkitUserSelect: 'none',
          // @ts-expect-error Fix ViewStyleProp to be recognized as an object
          ...style,
        }}
      >
        {children}
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const Draggable = ({
  id,
  spaceBetweenItems,
  isHorizontal,
  isGrabbing,
  isDragging,
  isDragIconHidden,
  isDragDisabled,
  itemContainerStyle,
  draggableWrapperStyle,
  children,
}: {
  id: UniqueIdentifier;
  index: number;
  spaceBetweenItems: number;
  isHorizontal?: boolean;
  isGrabbing: boolean;
  isDragging: boolean;
  isDragIconHidden: boolean;
  isDragDisabled: boolean;
  itemContainerStyle?: ViewStyleProp;
  draggableWrapperStyle?: ViewStyleProp;
  children: React.ReactNode;
}) => {
  const {setNodeRef, transform, transition} = useSortable({
    id,
    disabled: isDragDisabled,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.2 : 1,
      }}
    >
      <DraggableWrapper
        id={id}
        isGrabbing={isGrabbing}
        isDragDisabled={isDragDisabled}
        isDraggable={isHorizontal}
        style={draggableWrapperStyle}
      >
        <DragAndDropBuilder.ItemContainer
          spaceBetweenItems={spaceBetweenItems}
          isHorizontal={isHorizontal}
          style={itemContainerStyle}
        >
          {!isDragIconHidden && (
            <DraggableWrapper
              id={id}
              isGrabbing={isGrabbing}
              isDragDisabled={isDragDisabled}
              isDraggable={!isHorizontal}
              style={draggableWrapperStyle}
            >
              <DragAndDropBuilder.DragIcon isDisabled={isDragDisabled} />
            </DraggableWrapper>
          )}
          {children}
        </DragAndDropBuilder.ItemContainer>
      </DraggableWrapper>
    </div>
  );
};

export default Draggable;
