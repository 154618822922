// Libraries
import {DndContext, DragOverlay, closestCenter} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import _ from 'lodash';
import React from 'react';
import {createPortal} from 'react-dom';

// Supermove
import {ViewStyleProp} from '@supermove/styles/types';

// Relative
import DragAndDropBuilder from '../components/DragAndDropBuilder';
import Draggable from '../components/Draggable';
import {ItemIdExtractorType} from '../hooks/useItemIdExtractor';
import useReorderingDragAndDrop, {HandleReorderType} from '../hooks/useReorderingDragAndDrop';
import useSensors from '../hooks/useSensors';

const ReorderingDragAndDrop = <T,>({
  items,
  itemIdExtractor,
  renderItem,
  spaceBetweenItems = 0,
  handleReorder = () => {},
  isHorizontal = false,
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  itemContainerStyle,
}: {
  items: T[];
  itemIdExtractor: ItemIdExtractorType<T>;
  renderItem: (item: T, index: number) => React.ReactNode;
  spaceBetweenItems?: number;
  handleReorder?: HandleReorderType;
  isHorizontal?: boolean;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  itemContainerStyle?: ViewStyleProp;
}) => {
  const {
    grabbedItem,
    isDragging,
    handleDragEnd,
    handleDragPending,
    handleDragAbort,
    handleDragStart,
    getItemId,
  } = useReorderingDragAndDrop({
    items,
    itemIdExtractor,
    handleReorder,
  });
  const sensors = useSensors();

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragPending={handleDragPending}
      onDragAbort={handleDragAbort}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      autoScroll={{enabled: false}}
    >
      <div
        style={{
          flexDirection: isHorizontal ? 'row' : 'column',
          marginLeft: isHorizontal ? -spaceBetweenItems / 2 : 0,
          marginRight: isHorizontal ? -spaceBetweenItems / 2 : 0,
          marginTop: isHorizontal ? 0 : -spaceBetweenItems / 2,
          marginBottom: isHorizontal ? 0 : -spaceBetweenItems / 2,
        }}
      >
        <SortableContext
          items={items.map(getItemId)}
          strategy={isHorizontal ? horizontalListSortingStrategy : verticalListSortingStrategy}
        >
          {items.map((item, index) => {
            const itemId = getItemId(item);
            return (
              <Draggable
                key={itemId}
                id={itemId}
                index={index}
                spaceBetweenItems={spaceBetweenItems}
                isHorizontal={isHorizontal}
                isGrabbing={!!grabbedItem}
                isDragging={isDragging && !!grabbedItem && getItemId(grabbedItem) === itemId}
                isDragIconHidden={isDisabled || isHorizontal}
                isDragDisabled={isDisabled || isDisabledWithVisibleIcons}
                itemContainerStyle={itemContainerStyle}
              >
                {renderItem(item, index)}
              </Draggable>
            );
          })}
        </SortableContext>
      </div>

      {createPortal(
        <DragOverlay style={{cursor: grabbedItem ? 'grabbing' : 'grab', alignItems: 'center'}}>
          {grabbedItem && (
            <DragAndDropBuilder.ItemContainer spaceBetweenItems={0} style={itemContainerStyle}>
              <DragAndDropBuilder.DragIcon />
              {renderItem(
                grabbedItem,
                _.findIndex(items, (item) => getItemId(item) === getItemId(grabbedItem)),
              )}
            </DragAndDropBuilder.ItemContainer>
          )}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

export default ReorderingDragAndDrop;
