// Libraries
import _ from 'lodash';

// Supermove
import {useCallback} from '@supermove/hooks';

export type ItemIdExtractorType<T> = keyof T | (keyof T)[] | ((item: T) => string);

const useItemIdExtractor = <T,>({itemIdExtractor}: {itemIdExtractor?: ItemIdExtractorType<T>}) => {
  const getItemId = useCallback(
    (item: T) => {
      if (typeof itemIdExtractor === 'string') {
        return _.toString(_.get(item, itemIdExtractor));
      }

      if (Array.isArray(itemIdExtractor)) {
        return itemIdExtractor.map((key) => _.get(item, key)).join('-');
      }

      if (typeof itemIdExtractor === 'function') {
        return _.toString(itemIdExtractor(item));
      }

      return _.toString(_.get(item, 'id'));
    },
    [itemIdExtractor],
  );

  return {getItemId};
};

export default useItemIdExtractor;
