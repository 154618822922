// Libraries
import {DragEndEvent, DragPendingEvent} from '@dnd-kit/core';
import _ from 'lodash';

// Supermove
import {useState} from '@supermove/hooks';

// Relative
import useItemIdExtractor, {ItemIdExtractorType} from './useItemIdExtractor';

export type HandleReorderType = (params: {fromIndex: number; toIndex: number}) => void;

const useReorderingDragAndDrop = <T,>({
  items,
  itemIdExtractor,
  handleReorder,
}: {
  items: T[];
  itemIdExtractor?: ItemIdExtractorType<T>;
  handleReorder: HandleReorderType;
}) => {
  const [grabbedItem, setGrabbedItem] = useState<T>();
  const [isDragging, setIsDragging] = useState(false);

  const {getItemId} = useItemIdExtractor<T>({
    itemIdExtractor,
  });

  const handleDragPending = ({id}: DragPendingEvent) => {
    const item = _.find(items, (item) => getItemId(item) === _.toString(id));
    setGrabbedItem(item);
  };

  const handleDragAbort = () => {
    setGrabbedItem(undefined);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    setGrabbedItem(undefined);

    const {active, over} = event;
    const activeItemId = active?.id;
    const overItemId = over?.id;
    const isSelfDrop = activeItemId === overItemId;

    if (!isSelfDrop && activeItemId && overItemId) {
      const fromIndex = _.findIndex(items, (item) => getItemId(item) === _.toString(activeItemId));
      const toIndex = _.findIndex(items, (item) => getItemId(item) === _.toString(overItemId));
      handleReorder({fromIndex, toIndex});
    }
  };

  return {
    // State
    grabbedItem,
    isDragging,

    // Handlers
    handleDragEnd,
    handleDragPending,
    handleDragAbort,
    handleDragStart,

    // Getters
    getItemId,
  };
};

export default useReorderingDragAndDrop;
